const toCarts = document.querySelectorAll(".to-cart");

if (toCarts) {
  toCarts.forEach((btnCartWrapper) => {
    const toCartBtns = btnCartWrapper.querySelector(".to-cart__btn");
    const toCartCounter = btnCartWrapper.querySelector(".to-cart__counter");
    const toCartInput = toCartCounter.querySelector(".to-cart__input");
    const toCartDecrement = toCartCounter.querySelector(".to-cart__decrement");
    const toCartIncrement = toCartCounter.querySelector(".to-cart__increment");

    let count = toCartInput.value;

    toCartBtns.addEventListener("click", () => {
      toCartBtns.style.display = "none";
      toCartCounter.style.display = "flex";
    });

    toCartInput.addEventListener("keyup", (e) => {
      const self = e.currentTarget;

      if (self.value === "0" || self.value < "0") {
        self.value = 1;
      }

      count = toCartInput.value;
    });

    toCartInput.addEventListener("change", (e) => {
      const self = e.currentTarget;

      if (!self.value) {
        self.value = 1;
      }

      count = toCartInput.value;
    });

    toCartIncrement.addEventListener("click", (e) => {
      e.preventDefault();
      count++;

      toCartInput.value = count;
    });

    toCartDecrement.addEventListener("click", (e) => {
      e.preventDefault();

      if (toCartInput.value > "1") {
        count--;
        toCartInput.value = count;
      } else if (toCartInput.value <= "1") {
        toCartBtns.style.display = "flex";
        toCartCounter.style.display = "none";
      }
    });
  });
}
