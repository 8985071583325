const burgerBtn = document.querySelector(".burger");
const mobileMenu = document.querySelector(".mobile-menu");
const html = document.querySelector("html");

if (burgerBtn) {
  burgerBtn.addEventListener("click", () => {
    burgerBtn.classList.toggle("is-active");
    mobileMenu.classList.toggle("is-active");
    html.classList.toggle("lock");
  });
}
