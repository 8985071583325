const cartProducts = document.querySelectorAll(".cart-product");

if (cartProducts) {
  cartProducts.forEach((cartProduct) => {
    const cartProductInput = cartProduct.querySelector(".cart-product__input");
    const cartProductDecrement = cartProduct.querySelector(".cart-product__decrement");
    const cartProductIncrement = cartProduct.querySelector(".cart-product__increment");

    let count = cartProductInput.value;

    if (+cartProductInput.value === 1) {
      cartProductDecrement.setAttribute("disabled", "disabled");
    }

    cartProductInput.addEventListener("keyup", (e) => {
      const input = e.currentTarget;

      if (input.value === "0" || input.value < "0") {
        input.value = 1;
      }

      count = cartProductInput.value;
    });

    cartProductInput.addEventListener("change", (e) => {
      const input = e.currentTarget;

      if (!input.value) {
        input.value = 1;
      }

      count = cartProductInput.value;
    });

    cartProductIncrement.addEventListener("click", (e) => {
      e.preventDefault();

      if (+cartProductInput.value < +cartProductInput.max) {
        count++;
        cartProductInput.value = count;
      } else {
        cartProductInput.value = cartProductInput.max;
        // cartProductIncrement.setAttribute("disabled", "disabled");
      }
    });

    cartProductIncrement.addEventListener("click", () => {
      if (+cartProductInput.value > 1) {
        cartProductDecrement.removeAttribute("disabled");
      }
    });

    cartProductDecrement.addEventListener("click", (e) => {
      e.preventDefault();

      if (+cartProductInput.value > 1) {
        count--;
        cartProductInput.value = count;
      }
    });

    cartProductDecrement.addEventListener("click", () => {
      if (+cartProductInput.value === 1) {
        cartProductDecrement.setAttribute("disabled", "disabled");
      }
    });
  });
}
