/* eslint-disable no-param-reassign */
function popups() {
  function bindpopup(triggerSelector, popupSelector, closeSelector, closeClickOverlay = true) {
    const trigger = document.querySelectorAll(triggerSelector);
    // eslint-disable-next-line no-shadow
    const popups = document.querySelectorAll(popupSelector);
    const close = document.querySelectorAll(closeSelector);
    const windows = document.querySelectorAll("[data-popup]");
    let fixBlocks = document.querySelectorAll(".fixed-scroll-js");

    if (!popups) return;

    function calcScroll() {
      if (window.innerWidth > 992) {
        const paddingOffset = window.innerWidth - document.documentElement.clientWidth;
        return paddingOffset;
      }
      const paddingOffset = 16;
      return paddingOffset;
    }
    const scroll = calcScroll();

    function popupOpenEvents() {
      fixBlocks = document.querySelectorAll(".fixed-scroll-js");
      windows.forEach((elem) => {
        elem.classList.remove("is-active");
      });

      popups.forEach((popup) => {
        popup.classList.add("is-active");

        if (document.body.classList.contains("lock")) {
          popup.style.paddingRight = "0px";
        } else {
          popup.style.paddingRight = `${scroll}px`;
        }
      });

      document.body.classList.add("lock-popup");
      document.body.style.paddingRight = `${scroll}px`;

      fixBlocks.forEach((el) => {
        if (el.classList.contains("fixed-scroll-js")) {
          el.style.paddingRight = `${scroll}px`;
        } else {
          el.style.paddingRight = "0px";
        }
      });
    }

    function popupProductOpenEvents(item) {
      fixBlocks = document.querySelectorAll(".fixed-scroll-js");
      windows.forEach((elem) => {
        elem.classList.remove("is-active");
      });

      const popupsProduct = document.querySelectorAll("[data-popup-id]");

      for (let i = 0; i < popupsProduct.length; i++) {
        const popupProduct = popupsProduct[i];
        if (popupProduct.dataset.popupId === item.dataset.productId) {
          popupProduct.classList.add("is-active");
        }

        if (document.body.classList.contains("lock")) {
          popupProduct.style.paddingRight = "0px";
        } else {
          popupProduct.style.paddingRight = `${scroll}px`;
        }
      }

      document.body.classList.add("lock-popup");
      document.body.style.paddingRight = `${scroll}px`;

      fixBlocks.forEach((el) => {
        if (el.classList.contains("fixed-scroll-js")) {
          el.style.paddingRight = `${scroll}px`;
        } else {
          el.style.paddingRight = "0px";
        }
      });
    }

    function popupCloseEvents() {
      fixBlocks = document.querySelectorAll(".fixed-scroll-js");
      windows.forEach((elem) => {
        elem.classList.remove("is-active");
      });

      popups.forEach((popup) => {
        popup.classList.remove("is-active");
        popup.style.paddingRight = "0px";
      });

      document.body.classList.remove("lock-popup");
      if (document.body.classList.contains("lock")) {
        document.body.style.paddingRight = `${scroll}px`;
        fixBlocks.forEach((el) => {
          el.style.paddingRight = `${scroll}px`;
        });
      } else {
        document.body.style.paddingRight = "0px";
        fixBlocks.forEach((el) => {
          el.style.paddingRight = "0px";
        });
      }
    }

    function handleEscape(event) {
      if (event.key === "Escape") {
        popupCloseEvents();
        document.removeEventListener("keydown", handleEscape);
      }
    }

    function popupOpen() {
      trigger.forEach((item) => {
        item.addEventListener("click", (e) => {
          if (e.target) {
            e.preventDefault();
          }

          if (item.hasAttribute("data-product-id")) {
            popupProductOpenEvents(item);
          } else {
            popupOpenEvents();
          }
          document.addEventListener("keydown", handleEscape);
        });
      });
    }
    popupOpen();

    function popupClose() {
      close.forEach((e) => {
        e.addEventListener("click", () => {
          popupCloseEvents();
        });
      });

      popups.forEach((popup) => {
        popup.addEventListener("click", (e) => {
          if (e.target === popup && closeClickOverlay) {
            popupCloseEvents();
          }
        });
      });

      document.removeEventListener("keydown", handleEscape);
    }
    popupClose();
  }

  bindpopup(".popup-feedback-link-js", ".popup-feedback", ".popup-feedback .popup__close-btn");
  bindpopup(".popup-order-link-js", ".popup-order", ".popup-order .popup__close-btn");
  bindpopup("[data-product-id]", "[data-popup-id]", ".popup-product .popup__close-btn");
  bindpopup(".popup-success-link-js", ".popup-success", ".popup-success .close");
  bindpopup(".popup-success-2-link-js", ".popup-success-2", ".popup-success-2 .close");
}

export default popups;
