const search = document.querySelector(".search");

if (search) {
  const searchBox = search.querySelector(".search__box");
  const searchOpen = search.querySelector(".search__open");
  const searchClose = search.querySelector(".search__close");

  const header = document.querySelector(".header");

  searchClose.style.display = "none";

  searchOpen.addEventListener("click", () => {
    searchBox.classList.add("is-active");
    searchOpen.style.display = "none";
    searchClose.style.display = "flex";
    header.classList.add("active");
  });

  searchClose.addEventListener("click", () => {
    searchBox.classList.remove("is-active");
    searchOpen.style.display = "flex";
    searchClose.style.display = "none";
    header.classList.remove("active");
  });
}
