/* eslint-disable*/
const header = document.querySelector(".header");
const sectionList = document.querySelectorAll("section");
const headerHeight = header.offsetHeight;
let lastScrollTop = 0;

let options = {
  root: null,
  rootMargin: "0px",
  threshold: [0.2, 0.2],
};

window.addEventListener("scroll", () => {
  let scrollDistance = window.scrollY;

  if (scrollDistance > 0) {
    header.classList.add("hidden");
  }

  if (scrollDistance > lastScrollTop) {
    header.classList.add("hidden");
  } else {
    header.classList.remove("hidden");
  }

  lastScrollTop = scrollDistance;

  if (scrollDistance === 0) {
    header.classList.remove("hidden");
  }
});
