/* eslint-disable */
const map = document.getElementById("map");

if (map) {
  const dataCoord = document.querySelector(".contacts__coord");
  const coordX = +dataCoord.dataset.x;
  const coordY = +dataCoord.dataset.y;

  let center = [`${coordX}`, `${coordY}`];
  function init() {
    let map = new ymaps.Map("map", {
      center: center,
      zoom: 17,
    });

    let placemark = new ymaps.Placemark(
      center,
      {},
      {
        iconLayout: "default#image",
        iconImageHref: `${require("./img/icon-marker.png")}`,
        iconImageSize: [48, 56],
        iconImageOffset: [-15, -70],
      },
    );

    map.controls.remove("geolocationControl"); // удаляем геолокацию
    map.controls.remove("searchControl"); // удаляем поиск
    map.controls.remove("trafficControl"); // удаляем контроль трафика
    map.controls.remove("typeSelector"); // удаляем тип
    //map.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
    //map.controls.remove('zoomControl'); // удаляем контрол зуммирования
    map.controls.remove("rulerControl"); // удаляем контрол правил
    map.behaviors.disable(["scrollZoom"]); // отключаем скролл карты (опционально)

    map.geoObjects.add(placemark);
  }
  ymaps.ready(init);
}
