const anchors = document.querySelector(".hero__link");

if(anchors) {
  anchors.addEventListener("click", (event) => {
    event.preventDefault();
    const blockId = anchors.getAttribute("data-href");
    document.querySelector(`${blockId}`).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  });
}
