const productsCard = document.querySelectorAll(".product-card");

if (productsCard) {
  productsCard.forEach((productCard) => {
    const productCardBtn = productCard.querySelector(".product-card__to-cart");
    const productCardCounter = productCard.querySelector(".product-card__cart-counter");
    const productCardInput = productCardCounter.querySelector(".product-card__input");
    const productCardDecrement = productCardCounter.querySelector(".product-card__decrement");
    const productCardIncrement = productCardCounter.querySelector(".product-card__increment");

    const productCardStructureIcons = productCard.querySelectorAll(".product-card__structure-icon");
    const productCardStructureContents = productCard.querySelectorAll(".product-card__structure-content");

    let count = productCardInput.value;

    productCardBtn.addEventListener("click", () => {
      productCardCounter.classList.add("is-open");
    });

    productCardInput.addEventListener("keyup", (e) => {
      const self = e.currentTarget;

      if (self.value === "0" || self.value < "0") {
        self.value = 1;
      }

      count = productCardInput.value;
    });

    productCardInput.addEventListener("change", (e) => {
      const self = e.currentTarget;

      if (!self.value) {
        self.value = 1;
      }

      count = productCardInput.value;
    });

    productCardIncrement.addEventListener("click", (e) => {
      e.preventDefault();
      count++;

      productCardInput.value = count;
    });

    productCardDecrement.addEventListener("click", (e) => {
      e.preventDefault();

      if (productCardInput.value > "1") {
        count--;
        productCardInput.value = count;
      } else if (productCardInput.value <= "1") {
        productCardCounter.classList.remove("is-open");
      }
    });


    productCardStructureIcons.forEach(productCardStructureIcon => {
      productCardStructureIcon.addEventListener('click', () => {
        productCardStructureIcon.classList.toggle('is-active')

        productCardStructureContents.forEach(productCardStructureContent => {
          productCardStructureContent.classList.toggle('is-active')
        })
      })
    })
  });
}
