import "./vendor";
import "./assets/svg/svg-sprite";
import "./scss/styles.scss";
import "./common.blocks/index";
import "./vue/index";

import popups from './common.blocks/ui/popups/popups.js';// eslint-disable-line

function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context("./", true, /\.(html)$/));

window.addEventListener('DOMContentLoaded', () => {
  popups();
});
