/* eslint-disable */
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const reviewsSwiper = new Swiper(".reviews__swiper", {
  direction: "horizontal",
  loop: true,
  slidesPerView: 1,
  spaceBetween: 16,

  pagination: {
    el: ".swiper-pagination",
  },

  navigation: {
    nextEl: ".reviews__button-next",
    prevEl: ".reviews__button-prev",
  },

  breakpoints: {
    576: {
      slidesPerView: 2,
    },

    768: {
      slidesPerView: 2.5,
    },

    992: {
      slidesPerView: 3,
      spaceBetween: 24,
      pagination: false,
    },
    1300: {
      slidesPerView: 3,
      spaceBetween: 32,
      pagination: false,
    },
  },
});

/* ====================================== */

const adviceSwiper = new Swiper(".advice__swiper", {
  direction: "horizontal",
  loop: true,
  slidesPerView: 1.1,
  spaceBetween: 16,
  speed: 3500,
  autoplay: {
    delay: 5000,
  },

  breakpoints: {
    576: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 2.5,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 32,
    },
  },
});

/* ====================================== */

const galleryBlockSwiper = document.querySelector(".gallery-block__swiper");

if (galleryBlockSwiper) {
  if (window.innerWidth <= 992 && galleryBlockSwiper.dataset.mobile == "false") {
    const gallerySwiper = new Swiper(galleryBlockSwiper, {
      direction: "horizontal",
      loop: true,
      slidesPerView: 1.3,
      spaceBetween: 16,
      speed: 3500,
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2.5,
        },
      },
    });

    galleryBlockSwiper.dataset.mobile === "true";
  } else if (window.innerWidth > 992) {
    galleryBlockSwiper.dataset.mobile = "false";

    if (galleryBlockSwiper.classList.contains("swiper-container-initialized")) {
      gallerySwiper.destroy();
    }
  }
}

/* ====================================== */

// import { gsap, Power2 } from "gsap";
// const circle = document.querySelector(".about__circle-wrapper");
// const circleItem = document.querySelector(".about__circle-item");

// const aboutSwiper = new Swiper(".about__swiper", {
//   loop: false,
//   speed: 2500,
//   parallax: true,
//   mousewheel: {
//     invert: false,
//     // releaseOnEdges: true,
//   },
//   init: false,

//   on: {
//     init: function () {
//       animOnScroll();
//       slidesLength();
//     },

//     slideNextTransitionStart: function () {
//       const circleItems = document.querySelectorAll(".about__circle-item");

//       gsap.to(circle, 2.5, {
//         rotation: "+=90",
//       });

//       circleItems.forEach((circleItem) => {
//         gsap.to(circleItem, 2.5, {
//           rotation: "-=90",
//         });
//       });
//     },

//     slidePrevTransitionStart: function () {
//       const circleItems = document.querySelectorAll(".about__circle-item");

//       gsap.to(circle, 2.5, {
//         rotation: "-=90",
//       });

//       circleItems.forEach((circleItem) => {
//         gsap.to(circleItem, 2.5, {
//           rotation: "+=90",
//         });
//       });
//     },
//   },
// });

// window.addEventListener("scroll", animOnScroll);

// function animOnScroll(params) {
//   const header = document.querySelector(".header");
//   const animItem = document.querySelector(".about__swiper");
//   const animItemHeight = animItem.offsetHeight;
//   const animItemOffset = offset(animItem).top;

//   let animItemPoint = window.innerHeight - animItemHeight;

//   if (pageYOffset > animItemOffset - animItemPoint && pageYOffset < animItemOffset + 10) {
//     animItem.classList.add("is-active");
//     header.classList.add("slide-hidden");
//     aboutSwiper.init();
//   } else {
//     animItem.classList.remove("is-active");
//     header.classList.remove("slide-hidden");
//   }
// }

// function slidesLength() {
//   for (let i = 0; i < aboutSwiper.slides.length; i++) {
//     const slide = aboutSwiper.slides[i];
//   }
// }

// function offset(el) {
//   const rect = el.getBoundingClientRect(),
//     scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
//     scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//   return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
// }

/* ====================================== */

const popupSwiper = new Swiper(".popup__swiper", {
  loop: false,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },

  pagination: {
    el: ".popup__pagination",
    type: "fraction",
  },

  navigation: {
    nextEl: ".popup__button-next",
    prevEl: ".popup__button-prev",
  },
});
